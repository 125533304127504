<template>
    <div :class="['profile-item', {'profile-item-add': addType}]">
        <a class="profile-item-remove" v-if="controllable" @click.prevent="onControl">{{ controlText }}</a>
        <div class="media">
            <img :src="img" class="avatar-sm" />
            <div class="media-body">
                <h6 class="media-title">{{ title }}</h6>
                <div class="media-text">
                    <slot>{{ text }}</slot>
                </div>
            </div>
        </div>
        <div class="mt-3" v-if="fullWidthText">
            <slot name="text"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProfileItem',
        props: {
            title: {
                type: String,
                required: true
            },
            text: String,
            logo: String,
            type: String,
            controllable: Boolean,
            controlText: String,
            fullWidthText: Boolean
        },
        computed: {
            img() {
                if (this.logo) return this.logo;

                if (this.type === 'add') {
                    return this.importImage('profile-item-add.svg');
                } else if (this.type === 'service') {
                    return this.importImage('service-placeholder.svg');
                } else if (this.type === 'credential') {
                    return this.importImage('credential-placeholder.svg');
                } else if (this.type === 'vehicle') {
                    return this.importImage('vehicle-placeholder.svg');
                } else if (this.type === 'reference') {
                    return this.importImage('file-placeholder.svg');
                }

                return this.importImage('img-placeholder.svg');
            },
            addType() {
                return this.type === 'add';
            }
        },
        methods: {
            onControl() {
                this.$emit('control');
            },
            importImage(path) {
                return require(`../../assets/images/${path}`);
            }
        }
    }
</script>
