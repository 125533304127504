<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Modal</div>
                    <div class="card-body">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#basicModal">
                            Launch modal
                        </button>
                        <div class="modal fade" id="basicModal" tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Modal title</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        This is a basic modal
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Static backdrop</div>
                    <div class="card-body">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#staticModal">
                            Launch modal
                        </button>
                        <div class="modal fade" id="staticModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Modal title</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        This is a modal
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Scrollable Modal</div>
                    <div class="card-body">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#scrollableModal">
                            Launch modal
                        </button>
                        <div class="modal fade" id="scrollableModal" tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-scrollable">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Modal title</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, ad aliquam consequatur corporis, fugit inventore itaque minima necessitatibus porro quisquam quod unde, veniam voluptas! Adipisci dolores exercitationem modi provident quae.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, ad aliquam consequatur corporis, fugit inventore itaque minima necessitatibus porro quisquam quod unde, veniam voluptas! Adipisci dolores exercitationem modi provident quae.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, ad aliquam consequatur corporis, fugit inventore itaque minima necessitatibus porro quisquam quod unde, veniam voluptas! Adipisci dolores exercitationem modi provident quae.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, ad aliquam consequatur corporis, fugit inventore itaque minima necessitatibus porro quisquam quod unde, veniam voluptas! Adipisci dolores exercitationem modi provident quae.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, ad aliquam consequatur corporis, fugit inventore itaque minima necessitatibus porro quisquam quod unde, veniam voluptas! Adipisci dolores exercitationem modi provident quae.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, ad aliquam consequatur corporis, fugit inventore itaque minima necessitatibus porro quisquam quod unde, veniam voluptas! Adipisci dolores exercitationem modi provident quae.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, ad aliquam consequatur corporis, fugit inventore itaque minima necessitatibus porro quisquam quod unde, veniam voluptas! Adipisci dolores exercitationem modi provident quae.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, ad aliquam consequatur corporis, fugit inventore itaque minima necessitatibus porro quisquam quod unde, veniam voluptas! Adipisci dolores exercitationem modi provident quae.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, ad aliquam consequatur corporis, fugit inventore itaque minima necessitatibus porro quisquam quod unde, veniam voluptas! Adipisci dolores exercitationem modi provident quae.</p>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Modal Sizing</div>
                    <div class="card-body">
                        <button type="button" class="btn btn-primary mr-2" data-toggle="modal" data-target="#smallModal">
                            Small modal
                        </button>
                        <button type="button" class="btn btn-primary mr-2" data-toggle="modal" data-target="#largeModal">
                            Large modal
                        </button>
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#extraLargeModal">
                            Extra large modal
                        </button>
                        <div class="modal fade" id="smallModal" tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog modal-sm">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Modal title</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        This is a modal
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="largeModal" tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Modal title</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        This is a modal
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="extraLargeModal" tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Modal title</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        This is a modal
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Vertically centered</div>
                    <div class="card-body">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#centeredModal">
                            Launch modal
                        </button>
                        <div class="modal fade" id="centeredModal" tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Modal title</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        This is a modal
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Modal Panel</div>
                    <div class="card-body">
                        <button type="button" class="btn btn-primary mr-2 mb-2" data-toggle="modal" data-target="#rightbarModal1">
                            Profile modal panel
                        </button>
                        <button type="button" class="btn btn-primary mr-2 mb-2" data-toggle="modal" data-target="#rightbarModal2">
                            Success modal panel
                        </button>
                        <button type="button" class="btn btn-primary mr-2 mb-2" data-toggle="modal" data-target="#rightbarModal3">
                            Popout - Withdraw Bid
                        </button>
                        <button type="button" class="btn btn-primary mr-2 mb-2" data-toggle="modal" data-target="#rightbarModal4">
                            Popout - Bid Successful
                        </button>
                        <button type="button" class="btn btn-primary mr-2 mb-2" data-toggle="modal" data-target="#rightbarModal5">
                            Popout - New Bid
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="rightbarModal1" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-panel">
                <div class="modal-content">
                    <div class="modal-header bg-primary">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <fa icon="arrow-left" class="mr-2" /> Back
                        </button>
                        <h5 class="modal-title">Driver Profile</h5>
                        <p class="modal-subtitle">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea</p>
                    </div>
                    <div class="modal-body p-0 bg-white">
                        <ul class="nav nav-tabs nav-tabs-modal nav-tabs-primary nav-justified" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" data-toggle="tab" href="#profileInfo" role="tab">
                                    <fa icon="user" />
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#profileVehicles" role="tab">
                                    <fa icon="car" />
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#profileServices" role="tab">
                                    <fa icon="shopping-cart" />
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#profileAreas" role="tab">
                                    <fa icon="map-marker" />
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#profileExperience" role="tab">
                                    <fa icon="flag" />
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#profileRating" role="tab">
                                    <fa icon="tachometer-alt" />
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#profileReferences" role="tab">
                                    <fa icon="file-alt" />
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#profileReviews" role="tab">
                                    <fa icon="comment" />
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content tab-content-modal">
                            <div class="tab-pane fade show active" id="profileInfo" role="tabpanel">
                                <user-summary :user="user" class="mb-4"/>
                                <p class="text-label small mb-1">Company Information</p>
                                <p class="small">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero.</p>
                                <div class="mt-4">
                                    <table class="table table-borderless table-sm">
                                        <tbody>
                                        <tr>
                                            <td class="text-label small">Company Name</td>
                                            <td>Small Courier Man</td>
                                        </tr>
                                        <tr>
                                            <td class="text-label small">Full Name</td>
                                            <td>Isaac Denna</td>
                                        </tr>
                                        <tr>
                                            <td class="text-label small">Phone Number</td>
                                            <td>(+12) 123 1234 123</td>
                                        </tr>
                                        <tr>
                                            <td class="text-label small">Email</td>
                                            <td>Isaac@SCM.com</td>
                                        </tr>
                                        <tr>
                                            <td class="text-label small">Address</td>
                                            <td>21 Somewhere street</td>
                                        </tr>
                                        <tr>
                                            <td class="text-label small">City</td>
                                            <td>American City</td>
                                        </tr>
                                        <tr>
                                            <td class="text-label small">Zip</td>
                                            <td>32001</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="profileVehicles" role="tabpanel">
                                <profile-item
                                    v-for="(vehicle, index) in vehicles"
                                    :key="index"
                                    :title="vehicle.type"
                                    type="vehicle"
                                    class="px-0"
                                >
                                    <p class="text-label font-weight-normal mb-2 small">Number of Vehicles: {{ vehicle.count }}</p>
                                    <div class="custom-control custom-checkbox mr-2">
                                        <input class="custom-control-input" type="checkbox" :id="`vCheck${index}`" :checked="vehicle.newer" disabled>
                                        <label class="custom-control-label small" :for="`vCheck${index}`">Are any vehicles 5 years or newer?</label>
                                    </div>
                                </profile-item>
                            </div>
                            <div class="tab-pane fade" id="profileServices" role="tabpanel">
                                <profile-item
                                    v-for="(service, index) in services"
                                    :key="index"
                                    :title="service.title"
                                    type="service"
                                    class="px-0"
                                >
                                    <div class="small">{{ service.text }}</div>
                                </profile-item>
                            </div>
                            <div class="tab-pane fade" id="profileAreas" role="tabpanel">
                                <img :src="mapUsa" alt="" />
                                <div class="mt-5">
                                    <h6 class="text-label small mb-3">Selected States</h6>
                                    <div class="row row-cols-2 row-cols-3">
                                        <div class="col">Louisiana</div>
                                        <div class="col">Maine</div>
                                        <div class="col">Maryland</div>
                                        <div class="col">Massachusetts</div>
                                        <div class="col">Michigan</div>
                                        <div class="col">Minnesota</div>
                                        <div class="col">Mississippi</div>
                                        <div class="col">Missouri</div>
                                        <div class="col">MontanaNebraska</div>
                                        <div class="col">Nevada</div>
                                        <div class="col">New Hampshire</div>
                                        <div class="col">New Jersey</div>
                                        <div class="col">New Mexico</div>
                                        <div class="col">New York</div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="profileExperience" role="tabpanel">
                                <profile-item
                                    v-for="(credential, index) in credentials"
                                    :key="index"
                                    :title="credential.job_title"
                                    type="credential"
                                    class="px-0"
                                >
                                    <p class="text-label font-weight-normal mb-1 small">{{ credential.company_name }}</p>
                                    <p class="text-label font-weight-normal mb-3 small">{{ credential.date }}</p>
                                    <p class="small">{{ credential.description }}</p>
                                    <a href="#" class="small">Read more</a>
                                </profile-item>
                            </div>
                            <div class="tab-pane fade" id="profileRating" role="tabpanel">
                                <div class="media mb-4">
                                    <div class="avatar-sm mr-3">
                                        <span class="avatar-title bg-primary rounded-md">
                                            <span class="h5  font-weight-black mb-0">4</span>
                                        </span>
                                    </div>
                                    <div class="media-body">
                                        <p class="text-label mb-1 small">Current rating</p>
                                        <p class="text-label small mb-0">8/10</p>
                                    </div>
                                </div>
                                <ul class="list-unstyled">
                                    <li class="media mb-3" v-for="(score, index) in scores" :key="index">
                                        <div class="custom-control custom-checkbox mr-2">
                                            <input class="custom-control-input" type="checkbox" :id="`check${index}`" :checked="score.checked" disabled>
                                            <label class="custom-control-label" :for="`check${index}`"></label>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="text-label small mb-3">{{ score.title }}</h6>
                                            <p class="small mb-0">{{ score.description }}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-pane fade" id="profileReferences" role="tabpanel">
                                <profile-item
                                    v-for="i in 2"
                                    :key="i"
                                    title="Job Title"
                                    type="reference"
                                    class="px-0"
                                    full-width-text
                                >
                                    <p class="small font-weight-semibold mb-0">Reference 001</p>
                                    <template v-slot:text>
                                        <div class="small">
                                            <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero.</div>
                                            <div class="mt-2">
                                                <a href="#" class="small">Read more</a>
                                            </div>
                                        </div>
                                    </template>
                                </profile-item>
                            </div>
                            <div class="tab-pane fade" id="profileReviews" role="tabpanel">
                                <profile-item
                                    v-for="(review, index) in reviews"
                                    :key="index"
                                    :title="review.name"
                                    logo="/assets/images/avatar.png"
                                    class="px-0"
                                    full-width-text
                                >
                                    <star-rating :rating="review.rating" />
                                    <template v-slot:text>
                                        <div class="small">
                                            <div>{{ review.review }}</div>
                                            <div class="mt-2">
                                                <a href="#" class="small">Read more</a>
                                            </div>
                                        </div>
                                    </template>
                                </profile-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="rightbarModal2" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-panel">
                <div class="modal-content">
                    <div class="modal-header bg-success">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <fa icon="arrow-left" class="mr-2" /> Back
                        </button>
                        <h5 class="modal-title">Account Update Successful</h5>
                        <p class="modal-subtitle">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea</p>
                    </div>
                    <div class="modal-body bg-white">
                        <div class="d-flex align-items-center justify-content-center flex-column h-100">
                            <img src="/assets/images/success-check.svg" alt="Success" class="mb-4" />
                            <p class="font-weight-semibold text-primary-dark lead">Congrats</p>
                            <p class="small">Your Account Details have been updated</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-rounded px-5">View my Profile</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="rightbarModal3" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-panel">
                <div class="modal-content">
                    <div class="modal-header bg-danger">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <fa icon="arrow-left" class="mr-2" /> Back
                        </button>
                        <h5 class="modal-title">Withdraw Bid Confirmation</h5>
                        <p class="modal-subtitle">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea</p>
                    </div>
                    <div class="modal-body bg-white">
                        <div class="d-flex align-items-center justify-content-center flex-column h-100 px-4">
                            <img src="/assets/images/danger-close.svg" alt="Warning" class="mb-4" />
                            <p class="font-weight-semibold text-primary-dark lead">Confirm Withdraw of Bid</p>
                            <p class="small">Are you sure you would like to withdraw your Bid</p>
                            <select class="custom-select custom-select-sm mt-3">
                                <option selected>Select Reason</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-rounded px-5">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="rightbarModal4" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-panel">
                <div class="modal-content">
                    <div class="modal-header bg-success">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <fa icon="arrow-left" class="mr-2" /> Back
                        </button>
                        <h5 class="modal-title">Bid Successful</h5>
                        <p class="modal-subtitle">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea</p>
                    </div>
                    <div class="modal-body bg-white">
                        <div class="d-flex align-items-center justify-content-center flex-column h-100">
                            <img src="/assets/images/success-check.svg" alt="Success" class="mb-4" />
                            <p class="font-weight-semibold text-primary-dark lead">Congrats</p>
                            <p class="small">Your bid has successfully been Submitted</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-rounded px-5">Show my Bids</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="rightbarModal5" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-panel">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <fa icon="arrow-left" class="mr-2" /> Back
                        </button>
                        <h5 class="modal-title">Route Work - New Bid</h5>
                        <p class="modal-subtitle">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea</p>
                    </div>
                    <div class="modal-body bg-white">
                        <p class="text-primary-dark">Bid per Route</p>
                        <table class="table table-borderless">
                            <thead>
                            <tr>
                                <th colspan="2" class="font-13 text-primary font-weight-normal">Description</th>
                                <th class="font-13 text-primary font-weight-normal" style="width: 150px;">Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <div class="font-10 text-primary">Job Name</div>
                                    <div class="text-primary-dark font-weight-semibold">Job Name</div>
                                </td>
                                <td>
                                    <div class="font-10 text-primary">Route name</div>
                                    <div class="text-primary-dark font-weight-semibold">Route name</div>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">$</div>
                                        </div>
                                        <input type="text" class="form-control" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-primary-dark text-right font-weight-semibold">Sub Total</td>
                                <td class="text-primary-dark text-right font-weight-semibold">$ 200.00</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-primary-dark font-weight-semibold">
                                    Fuel Surcharge <span class="icon-question"></span>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input type="text" class="form-control" />
                                        <div class="input-group-append">
                                            <div class="input-group-text">%</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="font-13">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-primary-dark text-right font-weight-semibold">Total Price (incl. Surcharge)</td>
                                <td class="text-primary-dark text-right font-weight-semibold">$ 210.00</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="form-group">
                            <label for="notes">Bidders Notes to Poster</label>
                            <textarea id="notes" name="notes" class="form-control"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-rounded px-5">Place My Bid</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mapUsa from '../../assets/images/map-usa.svg';
    import UserSummary from "../../components/UserSummary";
    import ProfileItem from "../../components/ProfileItem";
    import StarRating from "../../components/StarRating";

    export default {
        name: 'Modal',
        components: {
            StarRating,
            ProfileItem,
            UserSummary
        },
        data() {
            return {
                mapUsa,
                user: {
                    company_name: 'Company Name',
                    full_name: 'Name Surname',
                    zip_code: 'Zip Code',
                    reviews_count: 24,
                    rating: 3,
                    profile_photo_url: '/assets/images/avatar.png'
                },
                vehicles: [
                    {type: 'Full Size Pick-Up Truck', count: 5, newer: false},
                    {type: 'Semi Truck', count: 10, newer: true},
                ],
                services: [
                    {title: 'Document & Packages', text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,'},
                    {title: 'Medical Courier', text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,'},
                    {title: 'Food and Perishables', text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,'}
                ],
                credentials: [
                    {job_title: 'Heavy Truck Specialist Driver', company_name: 'Company Name', date: 'June 2018 - Present', 'description': 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero.'},
                    {job_title: 'Heavy Truck Specialist Driver', company_name: 'Company Name', date: 'June 2018 - Present', 'description': 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero.'},
                ],
                scores: [
                    {title: 'Do you have back up Vehicles?', checked: true, description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore'},
                    {title: 'Do you have back up Drivers?', checked: true, description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore'},
                    {title: 'Vehicle Insurance Liability', checked: true, description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore'},
                    {title: 'Courier Experience over 2 years', checked: true, description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore'},
                    {title: 'Background check within the last 12 months', checked: true, description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore'},
                    {title: 'Logistics/Tracking app', checked: true, description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore'},
                ],
                reviews: [
                    {name: 'Steve Barrett', rating: 3, review: 'Aenean sed lorem est. Sed quis neque ut nibh suscipit imperdiet ac non augue. Aenean ornare sit amet lectus non tristique. Nunc ut volutpat lectus. Nulla velit augue, pulvinar sed nisi sit amet,'},
                    {name: 'Matthew Foster', rating: 4, review: 'Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh at placerat tincidunt. Nam sem lacus, ornare non ante sed, ultricies fringilla massa. Ut congue, elit non tempus elementum, sem risus'}
                ]
            }
        }
    }
</script>
